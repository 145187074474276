.flex {
  display: flex;

  &-row {
    flex-direction: row;

    &-reverse {
      flex-direction: row-reverse;
    }
  }

  &-column {
    flex-direction: column;
  }

  &-nowrap {
    flex-wrap: nowrap;
  }

  &-wrap {
    flex-wrap: wrap;
  }
}

.align {
  &-items {
    &-start {
      align-items: start;
    }

    &-center {
      align-items: center;
    }

    &-end {
      align-items: end;
    }
  }

  &-content {
    &-start {
      align-content: start;
    }
  }
}

.justify {
  &-content {
    &__space-between {
      justify-content: space-between;
    }
    &__flex-end {
      justify-content: flex-end;
    }
    &__flex-start {
      justify-content: flex-start;
    }
    &__center {
      justify-content: center;
    }
  }
}

.scroll-bar {
  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background: #222222;
    border-radius: 2px;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(253, 80, 3, 0.5);
    border-radius: 2px;
  }
}

.page {
  width: 100%;
  max-width: 1670px;
  margin: 50px auto 0;
  padding: 0 20px;
}

.no-select {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media only screen and (max-width: 650px) {
  .page {
    padding: 0 5px;
    margin: 0 auto;
  }
}

