:root {
  /* исходный размер 1600x900 */

  --stage-mode: "desktop";

  --stage-max-width: 1600px; /* 1600px */
  --stage-max-height: 900px; /* 900px */

  --stage-width: 100%;
  --stage-height: 100vh;

  /* мобильный 320x568 */

  --stage-mobile-width: 480px; /* 320px */

  /* модальные окна */

  --stage-modal-max-width: calc(var(--stage-max-width) - 30%);
  --stage-modal-max-height: 600px;

  /* отступы */
  --margin-between-buttons: 12px 0;

  /* базовые цвета */

  --color-black-a: black;
  --color-black-b: #080403;
  --bg-color-body: #151515;
  --bg-color-no-banner: #FF3232;
  --error: #FF3232;

  --color-white-a: white;
  --color-red-a: red;
  --color-green-a: #11ff00;

  --color-highlight-primary: #302b2a;
  --color-highlight-secondary: var(--color-white-a);
  --color-brand-orange: #FD5003;
  --color-brand-orange-hover: #e14301;
  --color-bg-dark: #181818;
  --color-btn-dark: #222222;
  --color-btn-dark-hover: #2a2a2a;
  --color-text-secondary: #84827F;
  --color-bckg-menu-item: #191919;
  --color-text-menu-active: #202020;
  --color-input-placeholder: #4A4A4A;
  --color-input-bg: #222222;
  --color-spartacus-blue-1:  #3456FF;
  --color-spartacus-blue-2:  #2E54B6;
  --color-text-gray-2: #777777;
  --color-black-shade-3: #222222;
}

@media screen and (max-width: 480px) {
  :root {
    --stage-mode: "mobile";
    --stage-max-width: var(--stage-mobile-width);
    --stage-max-height: auto;
    --stage-height: auto;
  }
}
