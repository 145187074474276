@import '_vars.scss';
@import '_fonts.scss';
@import '_layout.scss';

*,
*:before,
*:after {
  box-sizing: border-box;
  text-decoration: none;
  outline: none;
  -webkit-tap-highlight-color: transparent;
}

body {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  margin: 0;
  -webkit-overflow-scrolling: touch;
  background-color: var(--bg-color-body);
  color: var(--color-white-a);
}

body > div[id='root'] {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100vw;
  height: 100%;
  overflow: hidden;
  position: relative;
}

h1,
h2,
h3,
h4 {
  margin: 0;
  padding: 0;
}

h1 {
  font-style: normal;
  font-weight: bold;
  font-size: 56px;
  line-height: 138%;
}

h2 {
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 138%;
}

textarea {
  resize: none;
}

button {
  border: none;
  outline: none;
  background: none;

  &.main-btn {
    border-radius: 8px;
    padding: 15px 16px;
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;
    color: var(--color-white-a);
    background: var(--color-brand-orange);
    transition: all 300ms ease;
    cursor: pointer;

    &:hover {
      background: var(--color-brand-orange-hover);
    }

    &:disabled {
      background: #4C4C4C;
      color: #777777;
    }
  }

  &.submit-btn {
    background: radial-gradient(39.59% 100% at 50.2% 100%, #FD7B03 0%, #FD5003 100%);
    box-shadow: 0 1px 11px rgba(253, 80, 3, 0.6);
    padding: 16px;
    border-radius: 10px;
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    color: #FFFFFF;
    cursor: pointer;
    transition: all 300ms ease;

    &:hover {
      box-shadow: 0 3px 13px rgba(245, 71, 0, 0.6);
    }

    &:disabled{
      background: #4C4C4C;
      color: #777777;
      box-shadow: none;
      cursor: not-allowed;
    }
  }

  &.continue-btn {
    padding: 14px 16px;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #9D9D9D;
    border: 1px solid #424242;
    border-radius: 10px;
    cursor: pointer;
    background: #191919;
    transition: all 300ms ease;

    &:hover {
      background: #151515;
    }
  }

  &.cancel-btn {
    padding: 14px 16px;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: var(--color-white-a);
    border: 1px solid transparent;
    border-radius: 10px;
    cursor: pointer;
    background: #282828;
    transition: all 300ms ease;

    &:hover {
      background: #242424;
    }
  }

  &.button-h38 {
    height: 38px;
    padding: 0 8px;
    font-size: 13px;
    line-height: 14px;
    border-radius: 8px;
    text-transform: none;

    &-hasIcon {
      img {
        margin-right: 4px;
      }
    }
  }
}


button:focus,
button:active,
button:hover {
  outline: none;
}

button[disabled] {
  background-color: #1a1a1a;
  cursor: not-allowed;
}

.spacer {
  display: block;

  &-w4 {
    width: 4px;
  }

  &-w8 {
    width: 8px;
  }

  &-w16 {
    width: 16px;
  }

  &-w32 {
    width: 32px;
  }

  &-h14 {
    height: 14px;
  }

  &-h16 {
    height: 16px;
  }

  &-h24 {
    height: 24px;
  }
}

a {
  color: inherit;
  text-decoration-color: var(--color-white-a);
}

p {
  margin: 0;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

[data-layout-mode='mobile'] h1 {
  font-size: 26px;
}

[data-layout-mode='mobile'] h2 {
  font-size: 15px;
}

[data-layout-mode='mobile'] button {
  font-size: 16px;
  padding: 0.8em 1.3em;
}

[data-layout-mode='mobile'] .Input {
  margin: 0.8em 0;
  height: 45px;
  min-width: unset;
}

.Head {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: var(--stage-width);
  min-height: var(--stage-height);

  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.75) 25%, rgba(0, 0, 0, 0.5) 50%, rgba(0, 0, 0, 1) 85%),
  url('/assets/images/background-cover.png');

  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  overflow: hidden;
  white-space: pre-line;
  vertical-align: bottom;
}

.Head::-webkit-scrollbar {
  width: 1px;
  height: 1px;
  background: transparent;
}

.Head::-webkit-scrollbar-track,
.Head::-webkit-scrollbar-thumb,
.Head::-webkit-scrollbar-corner {
  background: transparent;
}

.Head > h1 {
  text-align: center;
  color: var(--color-white-a);
  text-shadow: 0 0 38px var(--color-black-a);
  margin-top: 3em;
  max-width: 17em;
}

.Head > h2 {
  text-align: center;
  color: var(--color-white-a);
  text-shadow: 0 0 22px var(--color-black-a);
  margin-top: 0.6em;
}

.Head > button {
  margin-top: 3em;
  margin-bottom: 3em;
}

.WatchVideo {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding-top: 75px;
}

.WatchVideo_button {
  width: 40px;
  height: 40px;
  border-radius: 20px;
  background: #fff;
  padding: 13px 15px;
}

.WatchVideo_text {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
  color: #ffffff;
}

.WatchVideo_spacer {
  width: 16px;
}

[data-layout-mode='mobile'] .WatchVideo {
  padding-top: 1.5em;
}

.Head > .Modal.WhatIsYourProf {
}

.Head > .Modal.WhatIsYourProf .JoinNowTo {
  background: var(--color-black-a);
}

.Head > .Modal.WhatIsYourProf .JoinNowTo > h1 {
  font-size: 48px;
}

.Head > .Modal.WhatIsYourProf .JoinNowTo > ul {
  margin: 5.4em 0 0 0;
}

.Head > .Modal.WhatIsYourProf .JoinNowTo > ul > li {
  width: 136px;
  height: 90px;
  margin: 0 8px;
}

.Head > .Modal.WhatIsYourProf .JoinNowTo > ul > li > img {
  width: 55px;
}

.Head > .Modal.WhatIsYourProf .JoinNowTo > ul > li > p {
  font-size: 14px;
}

.Head > .Modal.WhatIsYourProf .JoinNowTo > .Input {
  width: 45.3%;
  margin: 0 0 2.5em 0;
  font-size: 14px;
  height: 52px;
}

.Head > .Modal.WhatIsYourProf {
}

.Head > .Modal.WhatIsYourProf .JoinNowToFinished {
  background: var(--color-black-a);
  height: 100%;
}

.Head > .Modal.WhatIsYourProf .JoinNowToFinished > h1 {
  font-size: 48px;
}

/* Mobile */

[data-layout-mode='mobile'] .Head {
  min-height: unset;
  height: 100vh;
}

[data-layout-mode='mobile'] .Head > h1 {
  margin-top: 5.2em;
  max-width: unset;
  padding: 0 1em;
}

[data-layout-mode='mobile'] .Head > h2 {
  margin-top: 1.75em;
}

[data-layout-mode='mobile'] .Head > button {
  margin-top: 4em;
  margin-bottom: unset;
}

/* Modal */

[data-layout-mode='mobile'] .Head > .Modal.WhatIsYourProf {
  height: 100vh;
}

/* Desktop */

/*
.SlickSlider .SlideItem[data-hightlighted="true"] > .wrapper > p {
    font-size: 18px;
    white-space: break-spaces !important;
} */

/* Mobile */

//[ data-layout-mode="mobile" ] .SlickSliderMobile {
//  display: flex ;
//  position: relative ;
//  flex-direction: column ;
//  align-items: flex-start ;
//  justify-content: flex-start ;
//  width: 100% ;
//  height: 58.25vh ;
//  background-color: var( --color-black-a ) ;
//  padding: 1em 0 1em 0 ;
//  overflow: hidden ;
//}
//[ data-layout-mode="mobile" ] .SlickSliderMobile > .slick-slider {
//  display: flex ;
//  justify-content: center ;
//  width: 100% ;
//  height: 100% ;
//  position: relative;
//}
//[ data-layout-mode="mobile" ] .SlickSliderMobile > .slick-slider > .slick-list {}
//[ data-layout-mode="mobile" ] .SlickSliderMobile > .slick-slider > .slick-list > .slick-track > .slick-slide {}
//[ data-layout-mode="mobile" ] .SlickSliderMobile > .slick-slider > .slick-list > .slick-track > .slick-slide > div {}
//[ data-layout-mode="mobile" ] .SlickSliderMobile > .slick-slider > .slick-list > .slick-track > .slick-slide > div > .SlideItem {
//
//}
//[ data-layout-mode="mobile" ] .SlickSliderMobile > .slick-slider > .slick-list > .slick-track > .slick-slide > div > .SlideItem > .wrapper {
//  padding-top: 2em ;
//  margin: 0 auto;
//  width: 70% ;
//  opacity: 1 ;
//}
//[ data-layout-mode="mobile" ] .SlickSliderMobile > .slick-slider > .slick-list > .slick-track > .slick-slide > div > .SlideItem > .wrapper > img {
//  width: 64px ;
//}
//[ data-layout-mode="mobile" ] .SlickSliderMobile > .slick-slider > .slick-list > .slick-track > .slick-slide > div > .SlideItem > .wrapper > p {
//  width: 100% ;
//}
//
//[ data-layout-mode="mobile" ] .SlickSliderMobile > .slick-slider > .slick-arrow {
//  top: 7.5em ;
//  z-index: 999 ;
//  cursor: pointer ;
//}
//[ data-layout-mode="mobile" ] .SlickSliderMobile > .slick-slider > .slick-arrow.slick-prev {
//  left: -14px ;
//}
//[ data-layout-mode="mobile" ] .SlickSliderMobile > .slick-slider > .slick-arrow.slick-next {
//  right: 4px ;
//}
//[ data-layout-mode="mobile" ] .SlickSliderMobile > .slick-slider .slick-dots button,
//[ data-layout-mode="mobile" ] .SlickSliderMobile > .slick-slider .SliderDots {
//  display: flex ;
//  flex-direction: row ;
//  position: absolute ;
//  bottom: 1em;
//}
//[ data-layout-mode="mobile" ] .SlickSliderMobile > .slick-slider .SliderDots > .Dot {
//  cursor: pointer ;
//  display: flex ;
//  background: white ;
//  width: 7px ;
//  height: 7px ;
//  border-radius: 100% ;
//  overflow: hidden ;
//  margin: 0 3px ;
//  opacity: .5 ;
//}
//[ data-layout-mode="mobile" ] .SlickSliderMobile > .slick-slider .SliderDots > .Dot.slick-active {
//  opacity: 1 ;
//}
.MainPage {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  width: var(--stage-width);
  max-width: var(--stage-max-width);
}

@keyframes doFlash {
  50% {
    opacity: 0;
  }
}

.Flash {
  animation: doFlash 1s infinite;
}

@keyframes doMoveToBottomFromSubBottom {
  0% {
    bottom: -5em;
  }
  75% {
    bottom: 1em;
  }
  100% {
    bottom: 0;
  }
}

.MoveToTopFromBottom {
  animation: doMoveToBottomFromSubBottom 500ms ease;
  animation-iteration-count: 1;
}

@keyframes doShowLabel {
  0% {
    top: -5em;
  }
  75% {
    height: 40px;
  }
  100% {
    height: 35px;
    top: 0;
  }
}

.ShowLabel {
  animation: doShowLabel 1s ease-in-out;
  animation-iteration-count: 1;
}

@keyframes doMoveGradient {
  100% {
    transform: rotate(1turn);
  }
}

.Busy {
  position: relative;
  z-index: 0;
  overflow: hidden;
}

.Busy::before {
  content: '';
  position: absolute;
  z-index: -2;
  left: -50%;
  top: -50%;
  width: 200%;
  height: 200%;
  background-color: #080808;
  background-repeat: no-repeat;
  background-size: 50% 50%, 50% 50%;
  background-position: 0 0, 100% 0, 100% 100%, 0 100%;
  background-image: linear-gradient(#757575, #1a1a1a);
  -webkit-animation: doMoveGradient 4s linear infinite;
  animation: doMoveGradient 4s linear infinite;
}

.Busy::after {
  content: '';
  position: absolute;
  z-index: -1;
  left: 1px;
  top: 1px;
  width: calc(100% - 2px);
  height: calc(100% - 2px);
  background: #1a1a1a;
}

.ErrorMessage {
  display: flex;
  color: var(--color-red-a);
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
}

.Input,
.Roles-container {
  flex-wrap: wrap;
  flex-direction: column;

  .input-validation {
    text-align: center;
    padding: 10px 0 0;
    color: var(--color-red-a);
  }
}

.Input > input,
.Input > textarea {
  display: flex;
  padding: 1em 0 1em 0;
  background: transparent;
  border: 2px solid #3d3d3d;
  max-width: 539px;
  min-width: 539px;
  width: 100%;
  height: 62px;
  text-align: center;
  color: var(--color-white-a);
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 26px;

  &.ng-invalid.ng-touched {
    border: 1px solid var(--color-red-a);
    color: var(--color-red-a);

    &::placeholder {
      color: var(--color-red-a);
    }
  }

  &:autofill,
  &:-webkit-autofill,
  &:-internal-autofill-selected,
  &:-webkit-autofill:focus {
    background: transparent !important;
    border: 2px solid #3d3d3d !important;
    color: var(--color-white-a) !important;
  }

  &:focus-visible {
    outline: 0;
  }
}

.Input:focus {
  outline: none;
  background-color: var(--color-highlight-primary);
  border: 2px solid var(--color-highlight-secondary);
}

.Input::placeholder {
  color: #3d3d3d;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 130%;
}

.Input:focus::placeholder {
  color: var(--color-white-a);
}

.Footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  width: var(--stage-width);
  /* height: 64px; */
  padding: 48px 0;
}

.Footer > ul {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  list-style: none;
  padding: 0;
  width: 100%;
}

.Footer > ul > li {
  display: flex;
  cursor: pointer;
  position: relative;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  color: var(--color-white-a);

  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 138%;

  margin: 0px 3em;
}

.Footer > ul > li > b {
  font-weight: inherit;
  opacity: 0.5;
}

[data-layout-mode='mobile'] .Footer {
  /* height: 15vh; */
  padding: 0;
  justify-content: flex-start;
  background-color: var(--color-black-b);
}

[data-layout-mode='mobile'] .Footer > ul {
  padding-top: 2.2em;
  flex-direction: column;
}

[data-layout-mode='mobile'] .Footer > ul > li {
  width: 100%;
  font-size: 12px;
  margin-bottom: 1em;
}

[data-layout-mode='mobile'] .Footer > ul > li > b {
}

[data-layout-mode='mobile'] .Footer > ul > li:nth-child(1) {
  flex-direction: column;
}

[data-layout-mode='mobile'] .Footer > ul > li:nth-child(1) > span {
  display: flex;
  font-size: 2.1em;
}

[data-layout-mode='mobile'] .Footer > ul > li:nth-child(3) {
  order: 4;
}

[data-layout-mode='mobile'] .Footer > ul > li:nth-child(4) {
  order: 3;
  padding: 1.2em 0;
}

.Copyrights {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: var(--stage-width);
  padding: 2em 0 0;
  /* height: calc( 32px * 2.5 ) ; */
}

.Copyrights > p {
  display: flex;
  cursor: pointer;
  position: relative;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  color: var(--color-white-a);
  opacity: 0.2;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 138%;

  margin: 0 3em;
}

[data-layout-mode='mobile'] .Copyrights {
  padding: 0 0 1em 0;
  background-color: var(--color-black-b) !important;
  width: 100%;
  /* height: unset ; */
}

[data-layout-mode='mobile'] .Copyrights > p {
  width: 63%;
  font-size: 12px;
  text-align: center;
}

.GetApp {
  display: flex;
  padding: 3em 1em 0 1em;
}

.GetApp img {
  max-width: 100%;
}

.GetApp_spacer {
  width: 24px;
}

[data-layout-mode='mobile'] .GetApp {
  padding: 1.5em 1em 0 1em;
}

.LanguageSelector {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.LanguageSelector .select {
  appearance: none;
  background-color: transparent;
  border: none;
  padding: 0 1em 0 0;
  margin: 0;
  width: 100%;
  font-family: inherit;
  font-size: inherit;
  cursor: inherit;
  line-height: inherit;
  outline: none;
  color: white;
}

.LanguageSelector .select::-ms-expand {
  display: none;
}

.LanguageSelector .select:root {
  --select-border: #777;
  --select-focus: blue;
  --select-arrow: var(--select-border);
}

.Roles {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  list-style: none;
  padding: 0;
  margin: 4.9em 0 0 0;
}

.Roles > li {
  display: flex;
  cursor: pointer;
  position: relative;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border: 2px solid #3d3d3d;
  box-sizing: border-box;

  width: 165px;
  height: 111px;

  margin: 0 11px;
}

.Roles > li[data-role-selected='true'],
.Roles > li:hover {
  background-color: var(--color-highlight-primary);
  border-color: var(--color-highlight-secondary);
}

.Roles > li > * {
  display: flex;
}

.Roles > li > span.symbol {
  position: absolute;
  top: -0.56em;
  font-family: 'Spartacus';
  font-size: 4em;
  color: var(--color-white-a);
  z-index: 1;

  &:after {
    content: '';
    display: block;
    width: 64px;
    height: 2px;
    position: absolute;
    top: 34px;
    background: black;
    z-index: -1;
  }
}

.Roles > li > p {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 130%;
  text-transform: uppercase;
  color: var(--color-white-a);
}

.Roles.Error > li {
  border: 1px solid var(--color-red-a);
}

.Roles.Error > li > p,
.Roles.Error > li > span.symbol {
  color: var(--color-red-a);
}

[data-layout-mode='mobile'] .Roles {
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  margin: 1.4em 0 0 0;
}

[data-layout-mode='mobile'] .Roles > li {
  margin: 23px 15px;
  padding: 0;
  width: calc(76% / 2 - 1px);
  height: 70px;
}

[data-layout-mode='mobile'] .Roles > li > p {
  font-size: 12px;
}

[data-layout-mode='mobile'] .Roles > li > span.symbol {
  top: -0.6em;
  font-size: 2.6em;
}

.TopLabel,
.TopLabelMobile {
  font-family: 'Spartacus' !important;
  font-style: normal;
  text-align: center;
  text-decoration: none;
  color: var(--color-white-a);
  margin-top: 22px;
  font-size: 2.23em;
}

.TopLabelMobile {
  display: none;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 35px;
  overflow: hidden;

  background-color: var(--color-white-a);
  color: var(--color-black-a) !important;

  margin: 0 !important;
  padding: 4px 0 5px 0;
  font-size: 1.7em;
}

[data-layout-mode='mobile'] .TopLabel {
  margin-top: 32px;
}

[data-layout-mode='mobile'] .TopLabelMobile {
  display: flex;
  z-index: 999;
}

.Main {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  width: var(--stage-width);
  overflow-x: hidden;
  overflow-y: auto;

  transition: opacity 3s;
}

.Main[data-enabled='false'] {
  opacity: 0;
}

.Main[data-enabled='true'] {
  opacity: 1;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: 'Gilroy', 'Helvetica Neue', sans-serif;
}

div.data-slide-image-id-0,
div.data-slide-image-id-0:after {
  content: url('/assets/images/slider-icon-001.svg');
}

div.data-slide-image-id-1,
div.data-slide-image-id-1:after {
  content: url('/assets/images/slider-icon-002.svg');
}

div.data-slide-image-id-2,
div.data-slide-image-id-2:after {
  content: url('/assets/images/slider-icon-003.svg');
}

div.data-slide-image-id-3,
div.data-slide-image-id-3:after {
  content: url('/assets/images/slider-icon-004.svg');
}

div.data-slide-image-id-4,
div.data-slide-image-id-4:after {
  content: url('/assets/images/slider-icon-005.svg');
}

div.data-slide-image-id-5,
div.data-slide-image-id-5:after {
  content: url('/assets/images/slider-icon-006.svg');
}

div.data-slide-image-id-6,
div.data-slide-image-id-6:after {
  content: url('/assets/images/slider-icon-007.svg');
}

div.data-slide-image-id {
  display: flex;
  width: 55px;
  height: auto;
  transition: all 1s;
}

.SlickSlider {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  height: 50vh;
  padding: 0 0 4em 0;
  background-color: var(--color-black-a);
  min-height: 230px;

  .slick-slider.slick-initialized {
    display: flex;
    min-height: 230px;
    align-items: center;
    overflow: hidden;
  }

  .slick-list {
    display: flex;
    margin: 0;
    padding: 0;
    height: 100%;
    align-items: center;
    align-self: flex-start;
  }

  .slick-slide {
    display: flex !important;
    height: 100%;
    min-height: 1px;
    justify-content: center;
    align-items: center;
  }

  .slick-track {
    display: flex;
    align-items: center;
    height: 20vh;
  }

  .slick-arrow {
    display: none !important;
  }
}

.SlideItem {
  background: transparent;
  outline: 0;

  & > .wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    overflow: hidden;
    opacity: 0.25;
    transition: all 1s;
  }

  & > .wrapper > p {
    display: flex;
    justify-content: center;
    text-align: center;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 130%;
    padding: 1em 0 0 0;
    /* align-items: center ; */
    color: var(--color-white-a);
    width: 20em;
    transition: all 1s;
    transform: scale(1);
  }
}

.SlickSlider .SlideItem.highlighted > .wrapper {
  opacity: 1;
  transform: scale(1.2);
}

.SlickSlider .SlideItem.highlighted > .wrapper > img {
  width: 70px;
  height: auto;
}

input.app-input-default {
  appearance: none;
  height: 36px;
  background: #272727;
  border-radius: 12px;
  border: 1px solid #373737;
  color: white;
  padding: 0 10px;

  &:focus,
  &:active,
  &:focus-visible {
    border: 1px solid #373737;
    outline: 0;
  }

  &::placeholder {
    color: #666;
  }

  &.app-input-hasError {
    border: 1px solid var(--color-red-a);
  }
}

h3 {
  &.app-heading {
    font-weight: 600;
    font-size: 20px;
    line-height: 14px;
    margin-bottom: 14px;
    color: var(--color-white-a);

    &-accent {
      color: var(--color-brand-orange);
    }
  }
}

p.app-text {
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.24px;
  margin: 0 0 14px;

  &-base {
    color: var(--color-white-a);
  }
}

.app-input-form {
  &-group {
    display: flex;
    flex-wrap: wrap;

    &-column {
      flex-direction: column;
    }
  }
}

.app-input {
  &-group {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    margin-bottom: 18px;

    label {
      font-weight: 500;
      font-size: 15px;
      line-height: 20px;
      color: var(--color-white-a);
      margin-bottom: 4px;
    }
  }

  &-error {
    padding-left: 10px;
    font-weight: 300;
    font-size: 12px;
    line-height: 18px;
    color: var(--color-red-a);
  }
}

.mat-form-field {
  margin-right: 8px;
}

.mat-snack-bar-container {
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.75);
  border-radius: 8px;
  color: var(--color-white-a);
  margin-bottom: 80px !important;
  margin-top: 80px !important;

  &-error {
    background: #3D1010;
  }

  &-warning {
    background: #583B00;
  }

  &-success {
    background: #39422E;
  }

  &-info {
    background: #191919;
  }
}

.badge {
  background-color: var(--color-brand-orange);
  line-height: 25px;
  text-align: center;
  height: 25px;
  border-radius: 12px;
  padding: 0 8px;
}

.skeleton-loader {
  background-color: #202020 !important;
}

.skeleton-loader.progress {
  background-image: linear-gradient(90deg, rgba(255, 255, 255, 0), var(--bg-color-body)) !important;

  &:before {
    opacity: .3;
  }
}

.main-input {
  background: #282828;
  border-radius: 8px;
  padding: 15px;
  width: 100%;
  outline: none;
  color: var(--color-white-a);
  border: 1.5px solid transparent;
  transition: all 300ms ease;

  &.invalid {
    color: var(--error);
    border-color: var(--error);
  }

  &::placeholder {
    color: rgba(255, 255, 255, 0.3);
  }

  &:focus {
    border: 1.5px solid #DBDBDB;
  }
}

.error-text {
  font-size: 13px;
  color: var(--error);
  margin-top: 3px;
}

.w-100 {
  width: 100%;
}

.required {
  &:after{
    content: '*';
  }
}

.account-personal-info-popup-panel {
  .mat-dialog-container {
    padding: 0;
  }
}
