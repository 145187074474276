@font-face {
    font-family: 'Gilroy';
    src: url('Gilroy-Extrabold.woff2') format('woff2'),
        url('Gilroy-Extrabold.woff') format('woff'),
        url('Gilroy-Extrabold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy';
    src: url('Gilroy-Light.woff2') format('woff2'),
        url('Gilroy-Light.woff') format('woff'),
        url('Gilroy-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy';
    src: url('Gilroy-Semibold.woff2') format('woff2'),
        url('Gilroy-Semibold.woff') format('woff'),
        url('Gilroy-Semibold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy';
    src: url('Gilroy-Bold.woff2') format('woff2'),
        url('Gilroy-Bold.woff') format('woff'),
        url('Gilroy-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy';
    src: url('Gilroy-Heavy.woff2') format('woff2'),
        url('Gilroy-Heavy.woff') format('woff'),
        url('Gilroy-Heavy.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy';
    src: url('Gilroy-Black.woff2') format('woff2'),
        url('Gilroy-Black.woff') format('woff'),
        url('Gilroy-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy';
    src: url('Gilroy-UltraLight.woff2') format('woff2'),
        url('Gilroy-UltraLight.woff') format('woff'),
        url('Gilroy-UltraLight.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy';
    src: url('Gilroy-Medium.woff2') format('woff2'),
        url('Gilroy-Medium.woff') format('woff'),
        url('Gilroy-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy';
    src: url('Gilroy-Thin.woff2') format('woff2'),
        url('Gilroy-Thin.woff') format('woff'),
        url('Gilroy-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy';
    src: url('Gilroy-Regular.woff2') format('woff2'),
        url('Gilroy-Regular.woff') format('woff'),
        url('Gilroy-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

